// src/mixins/commonMethods.js
import '@/css/news/home.scss'
import FootModuleLoader from '@/pages/components/FootModuleLoader.vue'
const navModule_4 = () => import('@/pages/modules/nav/navModule_4.vue') //代码切割

export default {
	components: {
		navModule_4,
		FootModuleLoader,
	},
	data() {
		return {
			firstAdVisible: false,
			secondAdVisible: false,
		}
	},
	computed: {
		adjustedItems() {
			const adjustedList = this.list.slice(1)
			return this.getAdjustedItems(this.themeConfig, adjustedList, 'adsPosPC', 'leadType', 'collectionType', 'bigCardType')
		},
	},
	async mounted() {
		console.log('-------------------mounted')
		this.initData()
	},
	methods: {
		invalidClass(type) {
			if (type === 'lead' && !this.themeConfig.leadType) {
				return 'params-invalid'
			}
			if (type === 'collection' && !this.themeConfig.collectionType) {
				return 'params-invalid'
			}
			return ''
		},
		handleWindowLoad() {
			console.log('Window loaded')
		},
	},
}
