import { List } from 'vant'
import { mapGetters } from 'vuex'
import { PullRefresh } from 'vant'

const leadModule_1 = () => import('@/pages/modules/lead/leadModule_1.vue') //代码切割

const collectionModule_pc = () => import('@/pages/modules/collection/collectionModule_pc.vue') //代码切割

const topBanner_pc = () => import('@/pages/modules/bigcard/topBanner_pc.vue') //代码切割
const bigCardModule_pc = () => import('@/pages/modules/bigcard/bigCardModule_pc.vue') //代码切割

const textTypeModule = () => import('@/pages/modules/textTypeModulePC.vue') //代码切割

export default {
	components: {
		[List.name]: List, //按需加载vant
		[PullRefresh.name]: PullRefresh, //按需加载vant
		leadModule_1,
		collectionModule_pc,
		topBanner_pc,
		bigCardModule_pc,

		textTypeModule,
	},
	data() {
		return {
			list: [],
			hasNext: false,
			loading: false, // 控制上拉加载的加载状态
			finished: false, // 是否已加载完全部数据
			bomTitle: '-- END --', // 上拉加载完成时的文本
			page: 1,
			isinitialization: false,

			isData: false,
		}
	},
	computed: {
		...mapGetters({
			host: 'host',
			themeConfig: 'themeConfig',
			adsense: 'adsenseConfig',
			showDebug: 'showDebug',
			domainConfig: 'domainConfig',
			deviceType: 'deviceType',
		}),
	},
	methods: {
		getAdjustedItems(config, list, adPositionField) {
			let items = []
			let adsPos = config[adPositionField] || []
			list.forEach((item, index) => {
				// 检查当前位置是否应插入广告
				let adIndex = adsPos.indexOf(index + 1)
				if (adIndex !== -1) {
					// adIndex 是 index + 1 在 adsPos 中的序列位置
					items.push({ adtype: 'ad', position: index + 1, indexKey: adIndex })
				}
				// 根据 item 的 cardType 决定使用哪种模块
				let componentType
				switch (item.cardType) {
					case 1:
						componentType = `collectionModule_pc`
						break
					case 2:
						componentType = `leadModule_pc`
						break
					case 3:
						componentType = `bigCardModule_pc`
						break
					case 4:
						componentType = `textTypeModule`
						break
					default:
						componentType = `collectionModule_1` // 可以根据需求设置默认模块
				}
				items.push({ adtype: 'component', data: item, componentType })
			})
			return items
		},
		async onLoad() {
			console.log('触发onload方法')
			await this.getList()
		},
		async getList() {
			// console.log(`this.host-->${this.host}`);
			// 获取当前 URL
			let url = window.location.href
			// 移除 http:// 或 https:// 前缀
			let formattedUrl = url.replace(/^https?:\/\//, '')
			// 去掉路径和查询参数，只保留域名部分
			formattedUrl = formattedUrl.split('/')[0]
			// console.log(`url:${url} 格式化后的url:${formattedUrl}`)
			// 根据条件设置域名
			let domain = this.host ? this.host : formattedUrl
			console.log(`请求domain:${domain}`)
			// domain = 'buzzdailynews.info'
			domain = domain == 'localhost:8080' ? 'buzzdailynews.info' : domain
			// domain = 'buzzdailynews.info'

			let requestDomain = this.$store.state.requestDomain
			try {
				const res = await this.$global.server.getListPC({
					pageNum: this.page,
					pageSize: 30,
					domain: requestDomain ? requestDomain : domain, //yarn serve使用时兼容
					configDomain: domain,
				})
				console.log(res)
				this.page += 1
				this.loading = false
				this.finished = !res.data.hasNext

				if (res.code === 200) {
					let data = res.data.articleSummaryList
					this.list.push(...data)
					if (this.list.length > 0) {
						this.isData = true
					}
					this.hasNext = res.data.hasNext
				}

				if (!this.isinitialization) {
					this.isinitialization = true
					this.$eventrackFb('news_list_init', 'expose', {
						pve_cur: '/h5page/news',
						state: res.result_code === 200 && res.data.source.length > 1 ? 1 : 0,
					})
				}
			} catch (err) {
				console.log(err)
				this.isinitialization = true
				this.finished = true
				this.$eventrackFb('news_list_init_error', 'expose', {
					pve_cur: '/h5page/news',
					state: -1,
				})
			}
		},
	},
}
